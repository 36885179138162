import { sendGetRequest, sendPutRequest } from '@/services/api.service'
import { API_OPPORTUNITY } from '@/config/api.config'

export function getClient(clientId) {
  return sendGetRequest(`${API_OPPORTUNITY.BASE_API_URL}${API_OPPORTUNITY.CLIENT}/${clientId}`)
}

export function putClientTracking(clientId, payload) {
  return sendPutRequest(`${API_OPPORTUNITY.BASE_API_URL}${API_OPPORTUNITY.CLIENT}/${clientId}/tracking`, payload)
}

export function putClientMonetization(clientId, payload) {
  return sendPutRequest(`${API_OPPORTUNITY.BASE_API_URL}${API_OPPORTUNITY.CLIENT}/${clientId}/monetization`, payload)
}

export function putClientMap(clientId, payload) {
  return sendPutRequest(`${API_OPPORTUNITY.BASE_API_URL}${API_OPPORTUNITY.CLIENT}/${clientId}/map`, payload)
}
