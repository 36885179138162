<template>
  <form class="opportunity-tracking-section" @submit.prevent>
    <UiContainer large class="tw-mt-8 tw-pb-24">
      <v-card class="tw-p-6">
        <UiTitle :title="$t('opportunity.configuration.tracking.title')" class="tw-mb-8" large />
        <div class="tw-font-medium tw-text-base">
          {{ $t('opportunity.configuration.tracking.authorizeDomain.label') }}
        </div>

        <div class="tw-text-xs tw-text-gray-500 tw-my-1">
          {{ $t('opportunity.configuration.tracking.authorizeDomain.description') }}
        </div>

        <v-combobox
          v-model="trackingModel.authorizedDomains"
          :placeholder="$t('opportunity.configuration.tracking.authorizeDomain.placeholder')"
          multiple
          chips
          clearable
          deletable-chips
        />
      </v-card>

      <UiActions v-if="isModified" centered>
        <v-btn color="primary" type="submit" large @click="updateTracking">
          {{ $t('opportunity.configuration.save') }}
        </v-btn>
      </UiActions>
    </UiContainer>
  </form>
</template>

<script>
import UiActions from '@/components/UI/Actions.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  props: {
    tracking: {
      type: Object,
      required: true,
    },
  },
  components: {
    UiActions,
    UiTitle,
    UiContainer,
  },
  data() {
    return {
      trackingModel: {
        authorizedDomains: [],
      },
    }
  },
  created() {
    this.trackingModel = {
      ...this.trackingModel,
      ...this.tracking,
    }
  },
  methods: {
    updateTracking() {
      this.$emit('update', this.trackingModel)
    },
  },
  computed: {
    isModified() {
      return JSON.stringify(this.tracking) !== JSON.stringify(this.trackingModel)
    },
  },
}
</script>
