<template>
  <div class="tw-text-xs tw-text-gray-500 tw-flex tw-flex-col">
    <div>{{ label }}</div>
    <v-text-field
      :value="formatedValue"
      class="tw-font-normal tw-text-sm"
      :class="{ 'tw-rounded-none': rounded === false }"
      solo
      flat
      dense
      hide-details="auto"
      :outlined="outlined"
      :placeholder="placeholder"
      :disabled="disabled"
      :type="type"
      :min="min"
      :suffix="suffix"
      :error-messages="errorMessages"
      @input="emitInput"
    />
  </div>
</template>

<script>
export default {
  name: 'UiInputTextField',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => [],
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    min: {
      type: Number,
      required: false,
      default: undefined,
    },
    suffix: {
      type: String,
      required: false,
      default: undefined,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      formatedValue: null,
    }
  },
  methods: {
    emitInput(value) {
      if (this.suffix && value) {
        return this.$emit('input', `${value}${this.suffix}`)
      }
      this.$emit('input', value)
    },
  },
  created() {
    this.formatedValue = this.value
    if (this.suffix) {
      this.formatedValue = this.formatedValue.replace(this.suffix, '')
    }
  },
}
</script>
