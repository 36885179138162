<template>
  <div>
    <v-btn class="clickableText" color="primary" depressed outlined :disabled="disabled" @click="$emit('click')"
      ><v-icon left v-if="appendIcon"> {{ appendIcon }}</v-icon>
      {{ text }}
      <v-icon right v-if="prependIcon"> {{ prependIcon }}</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    color: {
      default: 'primary',
    },
    text: {
      type: String,
      required: true,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.clickableText {
  border: none;
}
.v-btn:hover:before {
  background-color: transparent;
}
.v-btn:before {
  display: none;
}
.v-ripple__animation {
  display: none;
}
</style>
