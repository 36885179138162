<template>
  <v-slider
    :min="min"
    :max="max"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :label="label"
    @input="set"
    :value="value"
    @click:append="increment"
    @click:prepend="decrement"
    hide-details
  ></v-slider>
</template>

<script>
export default {
  props: {
    prependIcon: {
      type: String,
      required: false,
    },
    appendIcon: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    modelValue: null,
  }),
  created() {
    this.modelValue = this.value
  },
  methods: {
    set(input) {
      this.modelValue = input
      this.$emit('input', this.modelValue)
    },
    decrement() {
      this.modelValue = this.modelValue - 1 || this.min
      this.$emit('input', this.modelValue)
    },
    increment() {
      this.modelValue = this.modelValue + 1 || this.max
      this.$emit('input', this.modelValue)
    },
  },
  computed: {
    label() {
      return this.modelValue.toString()
    },
  },
}
</script>
