<template>
  <div class="opportunity-fields-categories">
    <div
      class="opportunity-fields-categories__category tw-flex tw-row tw-gap-4 tw-my-4"
      v-for="(category, index) in categoriesModel"
      :key="`category ${index}`"
    >
      <v-text-field
        class="opportunity-fields-categories__category__name"
        v-model="category.category"
        flat
        dense
        outlined
        clearable
        :placeholder="$t('opportunity.configuration.monetization.category.name.label')"
        hide-details
      />

      <ui-input-price-in-cent
        ref="inputPrice"
        class="opportunity-fields-categories__category__price"
        :label="$t('opportunity.configuration.monetization.category.price.label')"
        :value-in-cents="category.value"
        :currency="currency"
        :locale="locale"
        :clearable="true"
        @onPriceChange="price => (category.value = price)"
        @clear="category.value = null"
      />

      <v-btn icon @click="deleteCategory(index)">
        <v-icon>{{ icons.mdiCloseCircle }}</v-icon>
      </v-btn>
    </div>

    <div class="tw-w-full tw-text-center tw-mt-4">
      <v-btn color="success" large icon @click="addCategory">
        <v-icon large>{{ icons.mdiPlusCircle }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiCloseCircle, mdiPlusCircle } from '@mdi/js'
import UiInputPriceInCent from '@/components/UI/input/PriceInCent.vue'

const CATEGORY_INIT = () => ({
  category: '',
  value: null,
})

export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  components: {
    UiInputPriceInCent,
  },
  data() {
    return {
      icons: {
        mdiCloseCircle,
        mdiPlusCircle,
      },
      categoriesModel: [CATEGORY_INIT()],
    }
  },
  created() {
    if (this.categories.length) {
      this.categoriesModel = this.categories
    }
  },
  computed: {
    isValid() {
      for (const inputPrice of this.$refs.inputPrice) {
        if (!inputPrice.isValid) return false
      }

      return true
    },
  },
  methods: {
    addCategory() {
      this.categoriesModel.push(CATEGORY_INIT())
    },
    deleteCategory(index) {
      this.categoriesModel.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.opportunity-fields-categories {
  &__category {
    &__price {
      flex-basis: 240px;
    }
  }
}
</style>
