<template>
  <div class="opportunity-fields-title-description">
    <div class="tw-font-medium tw-text-base">
      {{ label }}
    </div>

    <div v-if="description" class="tw-text-xs tw-text-gray-500 tw-my-1">
      {{ description }}
    </div>

    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: 'OpportunityFieldsTitleDescription',
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
