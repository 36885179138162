<template>
  <div>
    <div class="tw-my-4">
      <span class="tw-font-medium tw-text-sm tw-text-gray-600 tw-block">{{
        $t('opportunity.configuration.map.layers.divisionLevel.label')
      }}</span>
      <span class="tw-text-xs tw-text-gray-500 tw-my-1 tw-block">{{
        $t('opportunity.configuration.map.layers.divisionLevel.description')
      }}</span>

      <v-select
        :items="availableLevels"
        v-model="configuration.level"
        :error-messages="errorMessages.level"
        :label="levelLabel"
        solo
        flat
        outlined
        dense
        hide-details="auto"
        @blur="validate"
      />
    </div>

    <div class="tw-my-4">
      <span class="tw-font-medium tw-text-sm tw-text-gray-600 tw-block">{{
        $t('opportunity.configuration.map.layers.breakpoint.label')
      }}</span>
      <span class="tw-text-xs tw-text-gray-500 tw-my-1 tw-block">{{
        $t('opportunity.configuration.map.layers.breakpoint.description')
      }}</span>

      <v-slider
        v-model="configuration.breakpoint"
        :min="1"
        :max="18"
        :prepend-icon="icons.mdiMagnifyMinusOutline"
        :append-icon="icons.mdiMagnifyPlusOutline"
        :label="breakpointLabel"
        hide-details="auto"
        :error-messages="errorMessages.breakpoint"
        @blur="validate"
        @click:append="zoomIn"
        @click:prepend="zoomOut"
      ></v-slider>
    </div>

    <OpportunityFieldsTitleDescription
      class="tw-mt-9"
      :label="$t('opportunity.configuration.map.layers.style.label')"
      :description="$t('opportunity.configuration.map.layers.style.description')"
    >
      <template #content>
        <OpportunityConfigurationMapLayerStyle
          class="tw-mt-4"
          v-model="configuration.style.base"
          :error-messages="errorMessages.style.base"
        />
      </template>
    </OpportunityFieldsTitleDescription>

    <OpportunityFieldsTitleDescription
      class="tw-mt-9"
      :label="$t('opportunity.configuration.map.layers.rules.label')"
      :description="$t('opportunity.configuration.map.layers.rules.description')"
    >
      <template #content>
        <OpportunityConfigurationMapLayerRule
          v-for="(rule, index) in configuration.style.rules"
          v-model="configuration.style.rules[index]"
          class="tw-my-4"
          :key="index"
          :rule="rule"
          :index="index"
          @delete="deleteRule"
        />
        <UiClickableText
          class="tw-text-center tw-mt-4"
          :text="$t('opportunity.configuration.map.layers.rules.add')"
          :appendIcon="icons.mdiPlus"
          :disabled="ruleEditInProgress"
          @click="addRule"
        />
      </template>
    </OpportunityFieldsTitleDescription>
  </div>
</template>

<script>
import OpportunityFieldsTitleDescription from '@/components/Opportunity/Fields/TitleDescription.vue'
import OpportunityConfigurationMapLayerRule from './LayerConfigurationRules'
import { mdiMagnifyMinusOutline, mdiMagnifyPlusOutline } from '@mdi/js'
import OpportunityConfigurationMapLayerStyle from './LayerConfigurationStyle.vue'
import UiClickableText from '@/components/UI/ClickableText.vue'

export default {
  components: {
    OpportunityFieldsTitleDescription,
    OpportunityConfigurationMapLayerStyle,
    OpportunityConfigurationMapLayerRule,
    UiClickableText,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    availableLevels: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    icons: {
      mdiMagnifyMinusOutline,
      mdiMagnifyPlusOutline,
    },
    validationErrors: {},
    ruleEditInProgress: false,
  }),
  methods: {
    zoomOut() {
      this.configuration.breakpoint = this.configuration.breakpoint - 1 || 1
    },
    zoomIn() {
      this.configuration.breakpoint = this.configuration.breakpoint + 1 || 18
    },
    addRule() {
      this.ruleEditInProgress = true
      this.configuration.style.rules.push({
        style: {
          ...this.configuration.style.base,
        },
      })
    },
    ruleSaved() {
      this.ruleEditInProgress = false
    },
    deleteRule(index) {
      this.ruleEditInProgress = false
      this.configuration.style.rules.splice(index, 1)
    },
    validate() {
      this.validationErrors = this.errorMessages
    },
  },
  computed: {
    breakpointLabel() {
      return this.configuration.breakpoint?.toString() || '1'
    },
    levelLabel() {
      return `${this.configuration.level || ''}`
    },
  },
}
</script>
