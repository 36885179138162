<template>
  <div class="selectLangItem">
    <v-switch v-model="value" :disabled="disabled" @change="$emit('input', value)"></v-switch>
    <v-img
      class="tw-ml-2"
      :src="getFlagImgSrc(lang)"
      max-width="30"
      min-width="30"
      hide-details
      v-if="lang.locale && getFlagImgSrc(lang)"
    />
    <span class="tw-ml-2 tw-font-semibold">{{ lang.locale.toUpperCase() }}</span>
  </div>
</template>

<script>
export default {
  name: 'UIFlagSwitch',
  props: {
    lang: {
      type: Object,
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  data: () => ({
    value: false,
  }),
  methods: {
    getFlagImgSrc(item) {
      try {
        return require(`@/assets/images/flags/${item.locale.toLowerCase()}.svg`)
      } catch {
        return null
      }
    },
  },
  created() {
    this.value = this.lang.active || false
  },
}
</script>

<style lang="scss">
.selectLangItem {
  @apply tw-py-2 tw-px-3 tw-flex tw-items-center tw-bg-white;
  width: 135px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
  border-radius: 8px;

  .v-input--selection-controls {
    margin-top: 0;
  }
  .v-messages {
    display: none;
  }
}
</style>
