<template>
  <v-divider class="ui-separator" :class="contentClasses"></v-divider>
</template>

<script>
export default {
  name: 'UiSeparator',
  props: {
    contentClasses: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-separator {
  @apply tw-my-6 tw-border-gray-200 dark:tw-border-opacity-10;
}
</style>
