<template>
  <v-menu @input="val => (show = val)" :close-on-content-click="false" transition="scale-transition" min-width="auto">
    <template v-slot:activator="{ on }">
      <v-text-field
        class="tw-font-normal tw-text-sm"
        :value="color"
        id="brandColor"
        readonly
        solo
        flat
        outlined
        dense
        :placeholder="placeholder"
        v-on="on"
        :append-icon="icons.mdiEraser"
        @click:append="updateRemoveColor"
        hide-details="auto"
        @blur="$emit('blur')"
        :error-messages="errorMessages"
      >
        <template v-slot:prepend-inner>
          <v-avatar :id="id" @click="on.click" tile size="24" :color="color" />
        </template>
      </v-text-field>
    </template>
    <v-color-picker
      v-if="show"
      :value="color || ''"
      :mode.sync="colorMode"
      hide-mode-switch
      dot-size="20"
      @input="colorUpdate"
      @blur="$emit('blur')"
    />
  </v-menu>
</template>

<script>
import { mdiEraser } from '@mdi/js'

export default {
  name: 'UiInputColorPicker',
  props: {
    id: {
      type: String,
      required: true,
    },
    currentColor: {
      type: String,
      required: false,
      default: '',
    },
    colorMode: {
      type: String,
      required: false,
      default: 'hexa',
    },
    placeholder: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      icons: {
        mdiEraser,
      },
      color: null,
    }
  },
  created() {
    this.color = this.currentColor
    if (!this.clearable) {
      this.icons.mdiEraser = ''
    }
  },
  methods: {
    colorUpdate(color) {
      this.color = color
      this.$emit('colorUpdate', color)
    },
    async updateRemoveColor() {
      this.color = null
      this.$emit('colorUpdate', null)
      this.$emit('clear')
    },
  },
}
</script>
