<template>
  <v-slider
    :min="1"
    :max="100"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :label="label"
    :value="plainValue"
    @input="set"
    @click:append="increment"
    @click:prepend="decrement"
    hide-details="auto"
    :error-messages="errorMessages"
    @end="$emit('inputBlur')"
  ></v-slider>
</template>

<script>
export default {
  props: {
    prependIcon: {
      type: String,
      required: false,
    },
    appendIcon: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    modelValue: null,
    plainValue: null,
  }),
  created() {
    this.modelValue = this.value // decimal
    this.plainValue = this.value * 100
  },
  methods: {
    set(input) {
      this.plainValue = input
      this.modelValue = parseFloat((input / 100).toFixed(2))
      this.$emit('input', this.modelValue)
    },
    decrement() {
      this.modelValue = this.modelValue - 0.05 || 0.05
      this.plainValue = this.plainValue - 5 || 0
      this.$emit('input', this.modelValue)
    },
    increment() {
      this.modelValue = this.modelValue + 0.05 || 1
      this.plainValue = this.plainValue + 5 || 100
      this.$emit('input', this.modelValue)
    },
  },
  computed: {
    label() {
      return `${Math.round(this.modelValue * 100)} %`
    },
  },
}
</script>
