<template>
  <div class="opportunity-configuration">
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6" />
    </v-overlay>

    <div v-else>
      <UiBanner :title="$t('opportunity.configuration.title')" />

      <v-tabs v-model="currentTab">
        <v-tab v-for="(tab, index) in tabs" :key="`tab ${index}`" :href="`#${tab.key}`">
          {{ $t(`opportunity.configuration.${tab.key}.title`) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" class="tw-bg-transparent">
        <v-tab-item key="1" value="tracking">
          <OpportunityTrackingSection :tracking="tracking" @update="updateTracking" />
        </v-tab-item>

        <v-tab-item key="2" value="monetization">
          <OpportunityMonetizationSection
            :monetization="monetization"
            :locale="currentUser.locale"
            @update="updateMonetization"
          />
        </v-tab-item>

        <v-tab-item key="3" value="map">
          <OpportunityMapSection :map="map" @update="updateMap" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiBanner from '@/components/UI/Banner.vue'
import OpportunityTrackingSection from '@/components/Opportunity/Configuration/Sections/Tracking/TrackingSection.vue'
import OpportunityMonetizationSection from '@/components/Opportunity/Configuration/Sections/Monetization/MonetizationSection.vue'
import OpportunityMapSection from '@/components/Opportunity/Configuration/Sections/Map/MapSection.vue'
import {
  getClient,
  putClientTracking,
  putClientMonetization,
  putClientMap,
} from '../../services/opportunity/ClientConfiguration.service'

export default {
  name: 'ViewOpportunityConfiguration',
  components: {
    UiBanner,
    OpportunityMonetizationSection,
    OpportunityTrackingSection,
    OpportunityMapSection,
  },
  data() {
    return {
      currentTab: '',
      tracking: {},
      map: {},
      monetization: {},
    }
  },
  async created() {
    this.clientId = this.$route.params.clientId
    this.setUpdating(true)

    const client = await getClient(this.clientId)
    this.tracking = client.tracking
    this.monetization = client.monetization
    this.map = client.map
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      currentUser: state => state.backoffice.currentUser,
      updating: state => state.backoffice.updating,
    }),
    tabs() {
      return [{ key: 'tracking' }, { key: 'monetization' }, { key: 'map' }]
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',
    }),
    async formValidation(cb) {
      this.setUpdating(true)

      try {
        await cb()
        this.setAlert({
          color: 'success',
          text: this.$t('opportunity.configuration.form.success'),
        })
      } catch (error) {
        this.setAlert({
          color: 'error',
          text: this.$t('opportunity.configuration.form.error'),
        })
      }

      this.setUpdating(false)
    },
    updateTracking(trackingUpdate) {
      this.tracking = {
        ...this.tracking,
        ...trackingUpdate,
      }

      this.formValidation(() => putClientTracking(this.clientId, trackingUpdate))
    },
    updateMap(mapUpdate) {
      this.map = {
        ...this.map,
        ...mapUpdate,
      }

      this.formValidation(() => putClientMap(this.clientId, mapUpdate))
    },
    updateMonetization(monetizationUpdate) {
      this.monetization = {
        ...this.monetization,
        ...monetizationUpdate,
      }

      this.formValidation(() => putClientMonetization(this.clientId, monetizationUpdate))
    },
  },
}
</script>
