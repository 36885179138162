<template>
  <div class="tw-grid tw-grid-cols-2 tw-gap-6">
    <div>
      <span class="tw-font-medium tw-text-sm tw-text-gray-600 tw-block">{{
        $t('opportunity.configuration.map.layers.style.fillColor.label')
      }}</span>
      <span class="tw-text-xs tw-text-gray-500 tw-my-1 tw-block">{{
        $t('opportunity.configuration.map.layers.style.fillColor.description')
      }}</span>

      <UiInputColorPicker
        id="fillColor"
        :current-color="modifiedValue.fillColor"
        :clearable="true"
        :error-messages="errorMessages.fillColor"
        :placeholder="$t('opportunity.configuration.map.layers.style.fillColor.label')"
        @colorUpdate="color => (modifiedValue.fillColor = color)"
        @blur="emitChange"
        @clear="emitChange"
      />
    </div>

    <div>
      <span class="tw-font-medium tw-text-sm tw-text-gray-600 tw-block">{{
        $t('opportunity.configuration.map.layers.style.fillOpacity.label')
      }}</span>
      <span class="tw-text-xs tw-text-gray-500 tw-my-1 tw-block">{{
        $t('opportunity.configuration.map.layers.style.fillOpacity.description')
      }}</span>

      <UiInputPercentSlider
        v-model="modifiedValue.fillOpacity"
        :prepend-icon="icons.mdiMinus"
        :append-icon="icons.mdiPlus"
        :value="modifiedValue.fillOpacity"
        :error-messages="errorMessages.fillOpacity"
        @blur="emitChange"
      />
    </div>

    <div>
      <span class="tw-font-medium tw-text-sm tw-text-gray-600 tw-block">{{
        $t('opportunity.configuration.map.layers.style.strokeOpacity.label')
      }}</span>
      <span class="tw-text-xs tw-text-gray-500 tw-my-1 tw-block">{{
        $t('opportunity.configuration.map.layers.style.strokeOpacity.description')
      }}</span>

      <UiInputPercentSlider
        v-model="modifiedValue.strokeOpacity"
        :prepend-icon="icons.mdiMinus"
        :append-icon="icons.mdiPlus"
        :value="modifiedValue.strokeOpacity"
        :error-messages="errorMessages.strokeOpacity"
        @blur="emitChange"
      />
    </div>

    <div>
      <span class="tw-font-medium tw-text-sm tw-text-gray-600 tw-block">{{
        $t('opportunity.configuration.map.layers.style.strokeWeight.label')
      }}</span>
      <span class="tw-text-xs tw-text-gray-500 tw-my-1 tw-block">{{
        $t('opportunity.configuration.map.layers.style.strokeWeight.description')
      }}</span>

      <UiInputNumberSlider
        v-model="modifiedValue.strokeWeight"
        :prepend-icon="icons.mdiMinus"
        :append-icon="icons.mdiPlus"
        :value="modifiedValue.strokeWeight"
        :min="1"
        :max="10"
        :error-messages="errorMessages.strokeWeight"
      />
    </div>

    <div>
      <span class="tw-font-medium tw-text-sm tw-text-gray-600 tw-block">{{
        $t('opportunity.configuration.map.layers.style.strokeColor.label')
      }}</span>
      <span class="tw-text-xs tw-text-gray-500 tw-my-1 tw-block">{{
        $t('opportunity.configuration.map.layers.style.strokeColor.description')
      }}</span>
      <UiInputColorPicker
        id="strokeColor"
        :current-color="modifiedValue.strokeColor"
        :clearable="true"
        :error-messages="errorMessages.strokeColor"
        :placeholder="$t('opportunity.configuration.map.layers.style.strokeColor.label')"
        @colorUpdate="color => (modifiedValue.strokeColor = color)"
        @blur="emitChange"
        @clear="emitChange"
      />
    </div>

    <!-- Maybe a preview one day 🥹 -->
  </div>
</template>

<script>
import UiInputColorPicker from '@/components/UI/input/ColorPicker.vue'
import UiInputPercentSlider from '@/components/UI/input/PercentSlider.vue'
import UiInputNumberSlider from '@/components/UI/input/NumberSlider.vue'
import { mdiPlus, mdiMinus } from '@mdi/js'

export default {
  components: {
    UiInputColorPicker,
    UiInputPercentSlider,
    UiInputNumberSlider,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    errorMessages: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    icons: { mdiPlus, mdiMinus },
    validationErrors: {},
    modifiedValue: null,
  }),
  created() {
    this.modifiedValue = this.value
  },
  methods: {
    emitChange() {
      this.$emit('input', { ...this.modifiedValue }) // needed to avoid mutation after first emit
    },
  },
}
</script>
