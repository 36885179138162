<template>
  <div>
    <a
      v-if="fileLink"
      class="tw-whitespace-nowrap tw-overflow-hidden tw-block tw-overflow-ellipsis tw-text-xs tw-mb-1"
      :href="fileLink.link"
      :download="fileLink.text"
      target="_blank"
    >
      {{ $t(`forms.individual.notifications.email.logo.current`) }}
    </a>
    <v-card flat class="ui-file-input" :disabled="uploading">
      <div
        class="ui-file-input__overlay"
        :class="{ 'ui-file-input__overlay--dragging': dragging }"
        @dragover.prevent="dragover"
        @dragleave.prevent="dragleave"
        @drop.prevent="drop"
        @click.stop="$refs.file.click()"
      />
      <v-btn
        v-if="!isLoading && (modifiedFile || fileUrl)"
        @click.stop="deleteFile"
        class="tw-z-10"
        color="error"
        icon
        small
        absolute
        right
        top
      >
        <v-icon>
          {{ mdiDelete }}
        </v-icon>
      </v-btn>
      <v-progress-circular v-if="isLoading" indeterminate color="primary" />
      <v-icon size="52" v-if="!isLoading">{{ icon }}</v-icon>
      <input type="file" @change="onChange" ref="file" :accept="computedFileFormats" class="ui-file-input__file" />
      <UiSubtitle class="ui-file-input__caption" :subtitle="$t('ui.fileInput.drop')" v-if="dragging" />
      <UiSubtitle class="ui-file-input__caption" :subtitle="$t('ui.fileInput.selectOrDrop')" v-else-if="!defaultFile" />
      <UiSubtitle
        class="ui-file-input__caption"
        :subtitle="`${defaultFile.name} (${formatBytes(defaultFile.size, $i18n.locale)})`"
        v-else
      />
    </v-card>
  </div>
</template>

<script>
import { mdiCloudUploadOutline, mdiDelete } from '@mdi/js'
import { formatBytes } from '@/utils/utilities.util'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'UiInputFile',
  components: {
    UiSubtitle,
  },
  props: {
    defaultFile: {
      type: File,
      required: false,
      default: null,
    },
    // If the file is uploaded, give the fileUrl and not the file prop.
    fileUrl: {
      type: String,
      required: false,
      default: null,
    },
    fileFormats: {
      type: Array,
      required: false,
      default: () => ['.'],
    },
    icon: {
      type: String,
      required: false,
      default: mdiCloudUploadOutline,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    modifiedFile: null,
    dragging: false,
    formatBytes,
    mdiDelete,
  }),
  created() {
    this.modifiedFile = this.defaultFile
  },
  computed: {
    computedFileFormats() {
      return this.fileFormats.map(f => (f !== '.' ? `.${f}` : f)).join(',')
    },
    fileLink() {
      if (this.modifiedFile) {
        return {
          text: this.modifiedFile.name,
          link: window.URL.createObjectURL(this.modifiedFile),
        }
      }
      if (this.fileUrl) {
        return {
          text: 'file',
          link: this.fileUrl,
        }
      }
      return null
    },
  },
  methods: {
    dragover() {
      this.dragging = true
    },
    dragleave() {
      this.dragging = false
    },
    deleteFile() {
      this.$refs.file.value = null
      this.modifiedFile = null
      this.$emit('deleteFile')
    },
    drop(e) {
      this.dragging = false
      this.modifiedFile = e.dataTransfer.files[0]
      this.$emit('change', e.dataTransfer.files[0])
    },
    onChange(e) {
      if (e.target.files.length) {
        this.modifiedFile = e.target.files[0]
        this.$emit('change', e.target.files[0])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-file-input {
  @apply tw-relative tw-w-full tw-p-4 sm:tw-p-6 tw-h-36 tw-flex tw-flex-col tw-justify-center tw-items-center tw-overflow-hidden;
  @apply tw-rounded-2xl #{!important};
  background-color: $light-grey;
  &__overlay {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-border-2 tw-border-dashed tw-cursor-pointer tw-border-gray-400 dark:tw-border-opacity-10 tw-rounded-2xl;

    z-index: 0;

    &--dragging {
      @apply tw-border-solid tw-border-primary;
      @apply tw-bg-primary tw-bg-opacity-10;
    }
  }

  &__file {
    display: none;
  }

  &__caption {
    @apply tw-text-center tw-mt-2;
  }
}
</style>
