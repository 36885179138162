<template>
  <form class="opportunity-monetization-section" @submit.prevent>
    <UiContainer large class="tw-mt-8 tw-pb-24">
      <v-card class="tw-p-6">
        <UiTitle :title="$t('opportunity.configuration.monetization.title')" class="tw-mb-8" large />

        <OpportunityFieldsTitleDescription
          :label="$t('opportunity.configuration.monetization.currency.label')"
          :description="$t('opportunity.configuration.monetization.currency.description')"
        >
          <template #content>
            <v-select
              class="tw-mt-4"
              v-model="monetizationModel.currency"
              flat
              dense
              outlined
              clearable
              :items="currencies"
              :placeholder="$t('opportunity.configuration.monetization.currency.label')"
            />
          </template>
        </OpportunityFieldsTitleDescription>

        <OpportunityFieldsTitleDescription
          class="tw-mb-6"
          :label="$t('opportunity.configuration.monetization.average.label')"
          :description="$t('opportunity.configuration.monetization.average.description')"
        >
          <template #content>
            <ui-input-price-in-cent
              :value-in-cents="defaultValueInCents"
              :currency="monetizationModel.currency || currencies[0]"
              :locale="locale"
              :clearable="true"
              @onPriceChange="updateDefaultValue"
              @clear="clearDefaultValue"
            />
          </template>
        </OpportunityFieldsTitleDescription>

        <OpportunityFieldsTitleDescription
          :label="$t('opportunity.configuration.monetization.category.label')"
          :description="$t('opportunity.configuration.monetization.category.description')"
        >
          <template #content>
            <OpportunityFieldsCategories
              ref="categories"
              :categories="monetizationModel.products"
              :currency="monetizationModel.currency || currencies[0]"
              :locale="locale"
            />
          </template>
        </OpportunityFieldsTitleDescription>
      </v-card>

      <UiActions centered>
        <v-btn :color="formContainErrors ? 'error' : 'primary'" type="submit" large @click="updateMonetization">
          {{ $t('opportunity.configuration.save') }}
        </v-btn>
      </UiActions>
    </UiContainer>
  </form>
</template>

<script>
import UiActions from '@/components/UI/Actions.vue'
import UiTitle from '@/components/UI/Title.vue'
import { currencies } from '@/config/currencies.config'
import UiContainer from '@/components/UI/Container.vue'
import UiInputPriceInCent from '@/components/UI/input/PriceInCent.vue'
import OpportunityFieldsTitleDescription from '@/components/Opportunity/Fields/TitleDescription.vue'
import OpportunityFieldsCategories from '@/components/Opportunity/Fields/Categories.vue'

export default {
  props: {
    monetization: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  components: {
    UiInputPriceInCent,
    UiActions,
    UiTitle,
    UiContainer,
    OpportunityFieldsTitleDescription,
    OpportunityFieldsCategories,
  },
  data() {
    return {
      currencies,
      monetizationModel: {
        currency: null,
        defaultValue: null,
        products: [],
      },
      formContainErrors: false,
    }
  },
  created() {
    this.monetizationModel = {
      ...this.monetizationModel,
      ...this.monetization,
    }
  },
  computed: {
    defaultValueInCents() {
      return this.monetizationModel.defaultValue
    },
  },
  methods: {
    formIsValid() {
      return this.$refs.categories?.isValid
    },
    updateMonetization() {
      if (this.formIsValid()) {
        this.setUpdatedAverage()
        this.$emit('update', this.monetizationModel)
        this.formContainErrors = false
        return
      }
      this.formContainErrors = true
    },
    setUpdatedAverage() {
      this.monetizationModel.products = this.$refs.categories.categoriesModel
    },
    updateDefaultValue(price) {
      this.monetizationModel.defaultValue = price
    },
    clearDefaultValue() {
      this.monetizationModel.defaultValue = null
    },
  },
}
</script>
