<template>
  <v-card class="notification-edit tw-p-4">
    <v-icon class="tw-absolute tw--top-2 tw--right-2" small color="red" @click="deleteRule()">{{
      icons.mdiCloseCircle
    }}</v-icon>
    <div class="md:tw-flex">
      <div class="tw-flex tw-flex-col tw-w-full" v-if="ruleEditing">
        <div class="">
          {{ modelValue.label[$i18n.locale] }}
          <div class="md:tw-ml-4 md:tw-flex tw-flex-auto tw-w-full tw-items-center"></div>
        </div>
        <UiInputTabbedInput
          class="tw-my-4"
          :tabs="availableLocales"
          :title="$t('forms.individual.fields.labels.label')"
          :required="true"
          :default-content="modelValue.label"
          :current-tab="currentLocalePosition"
          :error-messages="errorMessages.label"
          v-model="modelValue.label"
        />
        <div class="tw-mt-4 tw-flex-row tw-items-center tw-flex-wrap">
          <div class="tw-flex-none tw-w-16 tw-mb-2 tw-align-text-top">
            {{ $t(`forms.individual.notifications.internal.email.edit.field`) }}
          </div>
          <div class="tw-flex-1 md:tw-ml-4">
            <div class="md:tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-mb-4">
              <v-select
                class="mb:tw-ml-4 tw-flex-1 tw-mb-2 md:tw-mb-0 md:tw-mr-4"
                v-model="modelValue.property"
                :items="comparableProperties"
                :placeholder="$t(`forms.individual.notifications.internal.email.edit.placeholder.field`)"
                :error-messages="errorMessages.property"
                item-text="name"
                hide-details
                flat
                outlined
                solo
                dense
              ></v-select>
              <v-select
                class="mb:tw-ml-4 tw-flex-1"
                v-model="modelValue.operator"
                :items="computedOperators"
                :placeholder="$t(`forms.individual.notifications.internal.email.edit.placeholder.operator`)"
                :error-messages="errorMessages.operator"
                item-text="name"
                hide-details
                flat
                outlined
                solo
                dense
              ></v-select>
            </div>
            <UiInputTextField
              class="tw-block mb:tw-ml-4"
              v-model.number="modelValue.comparator"
              :placeholder="$t(`forms.individual.notifications.internal.email.edit.placeholder.value`)"
              :error-messages="errorMessages.comparator"
              hide-details
              solo
              type="number"
            />

            <OpportunityFieldsTitleDescription
              class="tw-mt-9"
              :label="$t('opportunity.configuration.map.layers.style.label')"
              :description="$t('opportunity.configuration.map.layers.style.description')"
            >
              <template #content>
                <OpportunityConfigurationMapLayerStyle
                  class="tw-mt-4"
                  v-model="modelValue.style"
                  :error-messages="errorMessages.style"
                />
              </template>
            </OpportunityFieldsTitleDescription>
          </div>
          <div class="tw-text-center tw-mt-4">
            <v-btn x-large icon color="info" @click="saveRule()" :disabled="!ruleCanBeSaved">
              <v-icon x-large>{{ icons.mdiContentSaveCheckOutline }}</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-4 tw-w-full" v-else>
        <div class="tw-font-semibold tw-text-sm tw-text-gray-600 tw-my-auto">{{ modelValue.label.en }}</div>
        <div class="tw-text-xs tw-text-gray-600 tw-my-auto tw-text-center">
          {{ ruleSummary }}
        </div>
        <div class="tw-my-auto tw-text-center">
          <v-icon v-if="modelValue.style.fillColor" large :color="modelValue.style.fillColor">
            {{ icons.mdiSquareRounded }}</v-icon
          >
        </div>
        <div class="tw-text-right">
          <v-btn x-large icon color="info" @click="editRule()">
            <v-icon>{{ icons.mdiCircleEditOutline }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mdiCloseCircle, mdiContentSaveCheckOutline, mdiCircleEditOutline, mdiSquareRounded } from '@mdi/js'
import UiInputTextField from '@/components/UI/input/InputTextField.vue'
import OpportunityConfigurationMapLayerStyle from './LayerConfigurationStyle.vue'
import OpportunityFieldsTitleDescription from '@/components/Opportunity/Fields/TitleDescription.vue'
import UiInputTabbedInput from '@/components/UI/input/TabbedInput.vue'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import { clone } from '@/utils/utilities.util'

export default {
  name: 'UiNotificationEdit',
  components: {
    UiInputTextField,
    OpportunityConfigurationMapLayerStyle,
    OpportunityFieldsTitleDescription,
    UiInputTabbedInput,
  },
  mixins: [validationMixin],
  props: {
    rule: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    modelValue: {},
    ruleEditing: false,
    icons: {
      mdiCloseCircle,
      mdiContentSaveCheckOutline,
      mdiCircleEditOutline,
      mdiSquareRounded,
    },
    availableLocales: ['en', 'fr'], // this.$i18n.availableLocales returns the public locales aswell :/
    defaultStyle: {
      fillColor: null,
      fillOpacity: 0.5,
      strokeOpacity: 1,
      strokeWeight: 1,
      strokeColor: null,
    },
  }),
  created() {
    if (!this.rule.property) {
      // new rule so edit panel is open
      this.ruleEditing = true
    }
    this.modelValue = {
      label: { en: null, fr: null },
      property: null,
      operator: null,
      comparator: null,
      style: {
        ...this.defaultStyle,
        ...this.rule.style,
      },
      ...clone(this.rule),
    }
  },
  computed: {
    computedOperators() {
      return [
        {
          name: this.$t('operators.equals'),
          value: 'equals',
          symbol: '=',
        },
        {
          name: this.$t('operators.superior'),
          value: 'superior',
          symbol: '>',
        },
        {
          name: this.$t('operators.superiorEqual'),
          value: 'superior_equals',
          symbol: '>=',
        },
        {
          name: this.$t('operators.inferior'),
          value: 'inferior',
          symbol: '>',
        },
        {
          name: this.$t('operators.inferiorEqual'),
          value: 'inferior_equals',
          symbol: '<=',
        },
      ]
    },
    operatorSymbol() {
      return this.computedOperators.find(operator => this.modelValue.operator === operator.value)?.symbol || ''
    },
    comparableProperties() {
      return ['totalCount', 'totalValue', 'penetrationRate'] // 🤷
    },
    ruleCanBeSaved() {
      return !this.$v.$invalid
    },
    ruleSummary() {
      return `${this.modelValue.property || ''} ${this.operatorSymbol} ${this.modelValue.comparator}`
    },
    currentLocalePosition() {
      return this.availableLocales.indexOf(this.$i18n.locale)
    },

    errorMessages() {
      const rulesErrors = {
        label: [],
        property: [],
        operator: [],
        comparator: [],
        style: { fillColor: [], fillOpacity: [], strokeColor: [], strokeOpacity: [], strokeWeight: [] },
      }

      if (!this.$v.modelValue.label.en.required || !this.$v.modelValue.label.fr.required) {
        rulesErrors.label.push(this.$t('error.required'))
      }
      !this.$v.modelValue.property.required && rulesErrors.property.push(this.$t('error.required'))
      !this.$v.modelValue.operator.required && rulesErrors.operator.push(this.$t('error.required'))
      !this.$v.modelValue.comparator.required && rulesErrors.comparator.push(this.$t('error.required'))

      !this.$v.modelValue.style.fillColor.required && rulesErrors.style.fillColor.push(this.$t('error.required'))
      !this.$v.modelValue.style.fillOpacity.required && rulesErrors.style.fillOpacity.push(this.$t('error.required'))
      !this.$v.modelValue.style.strokeColor.required && rulesErrors.style.strokeColor.push(this.$t('error.required'))
      !this.$v.modelValue.style.strokeOpacity.required &&
        rulesErrors.style.strokeOpacity.push(this.$t('error.required'))
      !this.$v.modelValue.style.strokeWeight.required && rulesErrors.style.strokeWeight.push(this.$t('error.required'))

      return rulesErrors
    },
  },
  methods: {
    addNotificationCondition() {
      this.updateConditionStatus()
    },
    editRule() {
      this.ruleEditing = true
    },
    saveRule() {
      this.ruleEditing = false
      this.$emit('input', this.modelValue)
      this.$emit('ruleSaved', this.modelValue)
    },
    deleteRule() {
      this.$emit('delete', this.index)
    },
  },

  validations() {
    return {
      modelValue: {
        label: { en: { required }, fr: { required } },
        property: { required },
        operator: { required },
        comparator: { required },
        style: {
          fillColor: { required },
          fillOpacity: { required },
          strokeOpacity: { required },
          strokeWeight: { required },
          strokeColor: { required },
        },
      },
    }
  },
}
</script>

<style scoped lang="scss">
.notification-edit {
  &__condition {
    .v-input--is-disabled .v-input__slot {
      background: none !important;
    }
  }
}
</style>
