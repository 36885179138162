<template>
  <v-expansion-panels tile class="tw-z-0" :value="openedPanel">
    <v-expansion-panel v-for="(configuration, i) in configurations" :key="i">
      <v-expansion-panel-header class="tw-text-lg tw-font-semibold">
        {{
          configuration.level
            ? `${$t('opportunity.configuration.map.layers.level')} ${configuration.level}`
            : $t('opportunity.configuration.map.layers.new')
        }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <OpportunityConfigurationMapLayerConfiguration
          :configuration="configuration"
          :availableLevels="availableDivisionLevels"
          :error-messages="errorMessages[i]"
        />
        <UiSeparator />
        <div>
          <v-btn class="tw-block tw-mx-auto tw-mt-4" fab dark x-small color="error" @click="$emit('delete', i)"
            ><v-icon> {{ icons.mdiDelete }}</v-icon></v-btn
          >
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mdiDelete } from '@mdi/js'
import OpportunityConfigurationMapLayerConfiguration from './LayerConfiguration.vue'
import UiSeparator from '@/components/UI/Separator.vue'

export default {
  components: { OpportunityConfigurationMapLayerConfiguration, UiSeparator },
  props: {
    configurations: {
      type: Array,
      default: () => [],
    },
    openedPanel: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    icons: {
      mdiDelete,
    },
  }),
  computed: {
    divisionLevels() {
      return [
        { text: `${this.$t('opportunity.configuration.map.layers.level')} 1`, value: 1 },
        { text: `${this.$t('opportunity.configuration.map.layers.level')} 2`, value: 2 },
        { text: `${this.$t('opportunity.configuration.map.layers.level')} 3`, value: 3 },
        { text: `${this.$t('opportunity.configuration.map.layers.level')} 4`, value: 4 },
      ]
    },
    availableDivisionLevels() {
      return this.divisionLevels
        .filter(level => !this.configurations.find(configuration => configuration.level == level.value))
        .map(divisionLevel => divisionLevel.value)
    },
  },
}
</script>
