<template>
  <form class="opportunity-map-section" @submit.prevent>
    <UiContainer large class="tw-mt-8 tw-pb-24">
      <v-card class="tw-p-6">
        <UiTitle :title="$t('opportunity.configuration.map.title')" class="tw-mb-8" large />

        <OpportunityFieldsTitleDescription
          :label="$t('opportunity.configuration.map.mapId.label')"
          :description="$t('opportunity.configuration.map.mapId.description')"
        >
          <template #content>
            <v-text-field
              class="tw-mt-4"
              :placeholder="$t('opportunity.configuration.map.mapId.label')"
              v-model="mapModel.id"
              flat
              dense
              outlined
              clearable
            />
          </template>
        </OpportunityFieldsTitleDescription>

        <OpportunityFieldsTitleDescription
          :label="$t('opportunity.configuration.map.countries.label')"
          :description="$t('opportunity.configuration.map.countries.description')"
        >
          <template #content>
            <div class="tw-flex">
              <UiFlagSwitch
                class="tw-mt-4 tw-mr-4"
                v-for="country in countries"
                v-model="country.active"
                :key="country.locale"
                :lang="country"
                @input="toggleCountry($event, country)"
              />
            </div>
          </template>
        </OpportunityFieldsTitleDescription>
      </v-card>

      <v-card class="tw-mt-4 tw-bg-gray-100" v-if="mapModel.countries.length">
        <v-tabs class="opportunity-map-section-tabs" v-model="tab">
          <v-tab v-for="country in mapModel.countries" :key="country" :value="country">
            <v-img
              v-if="flags[country]"
              class="tw-ml-2"
              :src="flags[country]"
              max-width="30"
              min-width="30"
              hide-details
            />
            <!-- An empty span keeps images from disapearing when tabs are changed -->
            <span>{{ !flags[country] ? country : '' }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="country in mapModel.countries" :key="country">
            <OpportunityConfigurationMapLayerConfigurationList
              :configurations="currentLayer?.configurations || []"
              :openedPanel="newestEmptyConfiguration"
              :error-messages="currentLayerErrors.configurations"
              @delete="removeConfiguration($event, country)"
            />
          </v-tab-item>
        </v-tabs-items>
        <div class="tw-bg-white" v-if="!currentLayer || currentLayer.configurations.length < 4">
          <UiClickableText
            class="tw-text-center"
            :text="$t('opportunity.configuration.map.layers.add')"
            :appendIcon="icons.mdiPlus"
            @click="addLayerConfiguration()"
          />
        </div>
      </v-card>

      <UiActions v-if="isModified" centered>
        <v-btn
          color="primary"
          type="submit"
          large
          @click="() => (displaySaveConfirmOverlay = true)"
          :disabled="!mapCanBeSaved"
        >
          {{ $t('opportunity.configuration.save') }}
        </v-btn>
      </UiActions>
    </UiContainer>

    <UiConfirmOverlay
      :active="displaySaveConfirmOverlay"
      :title="$t('opportunity.configuration.map.overlay.save.title')"
      :body="$t('opportunity.configuration.map.overlay.save.body')"
      :loading="confirmSaveOverlayisLoading"
      @close="cancelSave"
      @confirm="updateMap"
    />
  </form>
</template>

<script>
import UiActions from '@/components/UI/Actions.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiClickableText from '@/components/UI/ClickableText.vue'
import OpportunityFieldsTitleDescription from '@/components/Opportunity/Fields/TitleDescription.vue'
import UiFlagSwitch from '@/components/UI/FlagSwitch.vue'
import OpportunityConfigurationMapLayerConfigurationList from './LayerConfigurationList.vue'
import UiConfirmOverlay from '@/components/UI/ConfirmOverlay.vue'
import { mdiPlus } from '@mdi/js'
import { clone } from '@/utils/utilities.util'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    map: {
      type: Object,
      required: true,
    },
  },
  mixins: [validationMixin],
  components: {
    UiActions,
    UiTitle,
    UiContainer,
    UiClickableText,
    UiFlagSwitch,
    OpportunityFieldsTitleDescription,
    OpportunityConfigurationMapLayerConfigurationList,
    UiConfirmOverlay,
  },
  data() {
    return {
      displaySaveConfirmOverlay: false,
      confirmSaveOverlayisLoading: false,
      availableCountries: ['US', 'CA'], // Should be replaced by a call to countries list
      mapModel: {
        id: null,
        countries: [],
        layers: [],
      },
      tab: null,
      flags: {},
      icons: {
        mdiPlus,
      },
      newestEmptyConfiguration: null,
    }
  },
  created() {
    this.mapModel = clone({
      ...this.mapModel,
      ...this.map,
    })
    for (const availableCountrie of this.availableCountries) {
      this.flags[availableCountrie] = this.getFlagImgSrc(availableCountrie)
    }
  },
  methods: {
    updateMap() {
      if (this.$v.$invalid) {
        return
      }
      this.$emit('update', this.mapModel)
      this.confirmDeleteLeadOverlayisLoading = false
    },
    toggleCountry(bool, country) {
      this.mapModel.countries = this.mapModel.countries.filter(countryCode => countryCode !== country.locale)
      if (bool) {
        this.mapModel.countries.push(country.locale)
      }
    },
    getFlagImgSrc(item) {
      try {
        return require(`@/assets/images/flags/${item.toLowerCase()}.svg`)
      } catch {
        return null
      }
    },
    addLayerConfiguration() {
      const defaultConfiguration = {
        style: {
          base: {
            fillOpacity: 0.5,
            strokeOpacity: 1,
            strokeWeight: 1,
          },
          rules: [],
        },
      }
      if (!this.currentLayer) {
        this.mapModel.layers.push({
          countryCode: this.currentCountry,
          configurations: [defaultConfiguration],
        })
      }
      const layer = this.currentLayer
      this.newestEmptyConfiguration = layer.configurations.push(defaultConfiguration) - 1
    },
    getLayerByCountry(country) {
      return this.mapModel.layers.find(layer => layer.countryCode === country)
    },
    removeConfiguration(i, country) {
      const layer = this.getLayerByCountry(country)
      layer.configurations.splice(i, 1)
      if (!layer.configurations.length) {
        this.mapModel.layers.splice(this.mapModel.layers.indexOf(this.currentLayer), 1)
      }
    },
    cancelSave() {
      this.displaySaveConfirmOverlay = false
    },
  },
  computed: {
    mapCanBeSaved() {
      return !this.$v.$invalid
    },
    isModified() {
      return JSON.stringify(this.map) !== JSON.stringify(this.mapModel)
    },
    countries() {
      return this.availableCountries.map(availableCountry => ({
        locale: availableCountry,
        active: this.mapModel.countries.includes(availableCountry),
      }))
    },
    currentCountry() {
      return this.mapModel.countries[this.tab]
    },
    currentLayer() {
      return this.mapModel.layers.find(layer => layer.countryCode === this.currentCountry)
    },
    currentLayerErrors() {
      const index = this.mapModel.layers.findIndex(layer => layer.countryCode === this.currentCountry)
      return this.layerErrors[index] || {}
    },
    layerErrors() {
      const layerErrors = []
      const layerValidations = Object.values(this.$v.mapModel.layers.$each.$iter)
      for (const layer of layerValidations) {
        layerErrors.push({
          configurations: Object.values(layer.configurations.$each.$iter).map(configurationValidation => {
            const configurationErrors = {
              level: [],
              breakpoint: [],
              style: { base: { fillColor: [], fillOpacity: [], strokeColor: [], strokeOpacity: [], strokeWeight: [] } },
            }
            !configurationValidation.level.required && configurationErrors.level.push(this.$t('error.required'))
            !configurationValidation.breakpoint.required &&
              configurationErrors.breakpoint.push(this.$t('error.required'))

            !configurationValidation.style.base.fillColor.required &&
              configurationErrors.style.base.fillColor.push(this.$t('error.required'))
            !configurationValidation.style.base.fillOpacity.required &&
              configurationErrors.style.base.fillOpacity.push(this.$t('error.required'))
            !configurationValidation.style.base.strokeColor.required &&
              configurationErrors.style.base.strokeColor.push(this.$t('error.required'))
            !configurationValidation.style.base.strokeOpacity.required &&
              configurationErrors.style.base.strokeOpacity.push(this.$t('error.required'))
            !configurationValidation.style.base.strokeWeight.required &&
              configurationErrors.style.base.strokeWeight.push(this.$t('error.required'))

            return configurationErrors
          }),
        })
      }

      return layerErrors
    },
  },
  validations() {
    return {
      mapModel: {
        layers: {
          $each: {
            configurations: {
              $each: {
                breakpoint: { required },
                level: { required },
                style: {
                  base: {
                    fillColor: { required },
                    fillOpacity: { required },
                    strokeOpacity: { required },
                    strokeWeight: { required },
                    strokeColor: { required },
                  },
                },
              },
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.opportunity-map-section-tabs :nth-child(2) {
  @apply tw-bg-gray-100;
}
</style>
