<template>
  <div class="ui-input-tabbed-input">
    <div>
      <div class="tw-text-xs tw-items-center">{{ title }}</div>
      <v-tabs v-model="currentInputTab" right @change="changeTab">
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab }}
          <div class="ui-input-tabbed-input__empty-tab" v-if="shouldDisplayBadge(tab)"></div>
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="currentInputTab">
      <v-tab-item v-for="tab in tabs" :key="tab">
        <VueMarkdownEditor
          v-if="!hasFile && markdownEditor"
          class="ui-input-tabbed-input__markdown"
          left-toolbar="undo redo clear h bold italic strikethrough quote ul ol table hr link image"
          right-toolbar="preview"
          height="400px"
          :value="modifiedContent[tab]"
          @change="$event => emitInput($event, tab)"
        />
        <UiInputTextField
          v-else-if="!hasFile && !markdownEditor"
          class="tw-mr-0"
          :value="modifiedContent[tab]"
          :placeholder="placeholder"
          :disabled="disabled"
          :error-messages="errorMessages"
          @input="emitInput($event, tab)"
        />
        <UiInputFile
          v-else-if="hasFile"
          class="tw-mt-1"
          :key="JSON.stringify(modifiedContent[tab])"
          :defaultFile="modifiedContent[tab]"
          :disabled="disabled"
          @change="emitChangeFile($event, tab)"
          @deleteFile="emitDeleteFile(tab)"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import UiInputTextField from '@/components/UI/input/InputTextField.vue'
import UiInputFile from '@/components/UI/input/File.vue'
import { clone } from '@/utils/utilities.util'
import { isEmpty } from '@/utils/helper.util'
import VueMarkdownEditor from '@/plugins/markdown'

export default {
  name: 'UiInputTabbedInput',
  components: {
    UiInputTextField,
    UiInputFile,
    VueMarkdownEditor,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    defaultContent: {
      type: Object,
      required: false,
      default: null,
    },
    currentTab: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    markdownEditor: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    hasFile: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentInputTab: null,
      modifiedContent: null,
    }
  },
  created() {
    this.modifiedContent = { ...this.defaultContent }
    this.currentInputTab = clone(this.currentTab)
  },
  methods: {
    emitInput(value, tab) {
      this.modifiedContent[tab] = isEmpty(value) ? null : value
      this.$emit('input', this.modifiedContent)
    },
    emitChangeFile(value, tab) {
      this.modifiedContent[tab] = value
      this.$emit('change', this.modifiedContent)
    },
    emitDeleteFile(tab) {
      this.modifiedContent[tab] = null
      this.$emit('deleteFile', tab)
    },
    changeTab(tab) {
      this.$emit('changeTab', tab)
    },
    shouldDisplayBadge(tab) {
      if (this.required) {
        return !this.modifiedContent[tab]
      }

      return Object.values(this.modifiedContent).some(content => content) && !this.modifiedContent[tab]
    },
    isEmpty,
  },
  watch: {
    currentTab: {
      handler(newTab, oldTab) {
        if (newTab !== oldTab) this.currentInputTab = newTab
      },
      immediate: false,
    },
  },
}
</script>

<style lang="scss">
.ui-input-tabbed-input {
  &__empty-tab {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: red;
  }

  &__markdown {
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;

    ul {
      padding-left: 0;
    }

    .vuepress-markdown-body {
      padding: 15px 20px 30px;
    }
  }

  .v-tab {
    min-width: 0 !important;
  }

  .v-tabs-bar {
    height: 24px !important;
  }
}
</style>
